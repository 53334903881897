import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import bemClassName from '../../utils/bem';

import './index.scss';
import { RootState } from '../../store';

const contacts = bemClassName('contacts');

const Contacts = () => {
  const contactEMail = useSelector((state: RootState) => state.contactData.contactEMail);
  const contactPhones = useSelector((state: RootState) => state.contactData.contactPhones);

  useEffect(() => {
    document.title = 'Контакты';
  }, []);

  return (
    <>
      <h2 className={contacts('title')}>Контакты</h2>
      <div className={contacts('inner')}>
        <div className={contacts('section')}>
          <h4 className={contacts('sub-title')}>Служба поддержки</h4>
          <div className={contacts('text-wrap')}>
            {contactPhones &&
              contactPhones.data &&
              contactPhones.data.map((items, id) => {
                return (
                  items &&
                  items.phone && (
                    <Link key={id} to={`tel:${items.phone.E164}`} className={contacts('text')}>
                      {items.phone.international} ({items.name})
                    </Link>
                  )
                );
              })}

            {contactEMail && (
              <Link to={`mailto:${contactEMail}`} className={contacts('text')}>
                {contactEMail.data}
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contacts;
