import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Pagination from '../../UI/Pagination';

import bemClassName from '../../utils/bem';

// import images from '../../assets/images/review-apartament.jpg';

import './index.scss';
import { RootState } from '../../store';
import formatedDateOfStay from '../../utils/historyBookingsUtils';
import MessageData from '../../API/MessageData';
import Loading from '../../UI/Loading';

const messages = bemClassName('messages');

const Messages = () => {
  const [dataMessages, setDataMessages] = useState<any[]>();
  const [metaData, setMetaData] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isLogin = useSelector((state: RootState) => state.autorizationStatus.isLogin);
  const contactPhones = useSelector((state: RootState) => state.contactData.contactPhones);

  const formatedDateUpdateChat = (date: string) => {
    const messageDate = new Date(date);
    const day = messageDate.toLocaleString('ru-RU', { day: 'numeric', month: 'short' });
    return day;
  };

  useEffect(() => {
    const controllerAbortMessages = new AbortController();
    setIsLoading(true);
    if (isLogin !== null && isLogin) {
      MessageData.getMessageDataAllChats(currentPage, controllerAbortMessages.signal).then(
        (item) => {
          if (item.data && item.meta) {
            setDataMessages(item.data);
            setMetaData(item.meta);
            setIsLoading(false);
          } else {
            setIsLoading(true);
          }
        },
      );
    }
    return () => {
      controllerAbortMessages.abort();
    };
  }, [isLogin, currentPage]);

  useEffect(() => {
    document.title = 'Сообщения';
  }, []);

  return (
    <>
      {!isLoading ? (
        <div className={messages('wrap')}>
          {dataMessages && dataMessages.length > 0 ? (
            <>
              <h2 className={messages('title')}>Все открытые чаты</h2>
              <div className={messages('list')}>
                {dataMessages &&
                  dataMessages.map((item: any) => {
                    return (
                      <Link to={`/account#messages_${item.chat_id}`} className={messages('item')}>
                        <picture className={messages('picture')}>
                          <img
                            className={messages('image')}
                            src={`${item.photos_url}/${item.photos[0]}-298x298.jpg`}
                            srcSet={`${item.photos_url}/${item.photos[0]}-596x596.jpg 2x, ${item.photos_url}/${item.photos[0]}-894x894.jpg 3x`}
                            alt=""
                          />
                        </picture>
                        <div className={messages('preview')}>
                          <h4 className={messages('preview-title')}>
                            <span className={messages('preview-title-text')}>
                              1-к. квартира на {item.address}
                            </span>
                            <span className={messages('preview-title-info')}>
                              <span className={messages('separator')} />
                              <span className={messages('description')}>{item.city}</span>
                              <span className={messages('separator')} />
                              {item.created_at && item.updated_at && (
                                <span className={messages('description')}>
                                  {formatedDateOfStay(item.created_at, item.updated_at)}
                                </span>
                              )}
                            </span>
                          </h4>
                          {item.message && (
                            <p className={messages('description', { message_prev: true })}>
                              {item.message.message}
                            </p>
                          )}
                        </div>
                        <div className={messages('info')}>
                          <p className={messages('date')}>
                            {formatedDateUpdateChat(item.updated_at)}
                          </p>
                          {item.unread_count > 0 && (
                            <p className={messages('count')}>{item.unread_count}</p>
                          )}
                        </div>
                      </Link>
                    );
                  })}
              </div>
            </>
          ) : (
            <div className={messages('error')}>
              <h2>Сообщений пока нет</h2>
              <p>
                Чаты доступны по квартирам, которые были забронированы, или на которые Вы оставили
                заявку на бронирование. Для связи с нами по вопросам, не связанным с текущими
                заявками или бронированиями, воспользуйтесь контактами службы поддержки.
              </p>
              <h3>Служба поддержки</h3>
              <div className={messages('company-info')}>
                {contactPhones &&
                  contactPhones.data &&
                  contactPhones.data.map((items, id) => {
                    return (
                      items &&
                      items.phone && (
                        <Link key={id} to={`tel:${items.phone.E164}`} className={messages('text')}>
                          {items.phone.international} ({items.name})
                        </Link>
                      )
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loading externalClassName={messages('loading')} />
      )}
      {!isLoading &&
        metaData &&
        dataMessages &&
        dataMessages.length > 0 &&
        metaData.pages !== 1 && (
          <Pagination
            totalPageCount={metaData.pages}
            currentPage={currentPage}
            externalClassName={messages('pagination')}
            handleExternal={(value: number) => setCurrentPage(value)}
          />
        )}
    </>
  );
};

export default Messages;
