import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../store';
import { toggleMenuModal } from '../../store/reducers/modal/modalMenuSlice';
import {
  logout,
  setAccessToken,
  setExpires,
} from '../../store/reducers/profile/autorizationStatutsSlice';
import getAuthUrl from '../../utils/getAuthUrl';

import Icon from '../../UI/Icon';

import bemClassName from '../../utils/bem';

import './index.scss';
import PrivateData from '../../API/PrivateData';
import { CHANGE_PASSWORD } from '../../app_constants';

const headerLinks = bemClassName('header-links');

const HeaderLinks = () => {
  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);
  const contacntHelperPhones = useSelector(
    (state: RootState) => state.contactData.contacntHelperPhones,
  );
  const isLogin = useSelector((state: RootState) => state.autorizationStatus.isLogin);
  const isPaswordVerified = useSelector(
    (state: RootState) => state.autorizationStatus.isPaswordVerified,
  );
  const totalBasketPrice = useSelector((state: RootState) => state.basketData.totalBasketPrice);

  // const navigate = useNavigate();

  const redirectToLogin = (event: React.BaseSyntheticEvent<MouseEvent>) => {
    event.preventDefault();
    const url = getAuthUrl();
    if (url && url !== undefined) {
      window.location.href = url;
    }
  };
  const dispatch = useDispatch();

  const handlerLogout = async () => {
    await PrivateData.getPrivateLoggingOut();
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('tokenExpirationTime');
    dispatch(setAccessToken(''));
    dispatch(setExpires(0));
    dispatch(logout());
  };

  const formatedHeaderTotalPrice = (value: number) => {
    const result = new Intl.NumberFormat('ru-RU').format(value ?? 0);
    return result;
  };

  return (
    <div className={headerLinks()}>
      <Link className={headerLinks('item', { cart: true })} to="/account#current-bookings">
        {(totalBasketPrice && formatedHeaderTotalPrice(totalBasketPrice)) ?? 0} ₽
      </Link>
      {windowWidth >= 768 ? (
        <>
          <Link className={headerLinks('item')} to="/account#favourites">
            <Icon externalClassName={headerLinks('icon')} iconName="like" />
            избранное
          </Link>
          <Link className={headerLinks('item')} to="/account#current-bookings">
            <Icon externalClassName={headerLinks('icon')} iconName="booking" />
            бронирования
          </Link>
          <div className={headerLinks('item')}>
            <Icon externalClassName={headerLinks('icon')} iconName="help" />
            помощь
            <div className={headerLinks('submenu-wrapper')}>
              <div className={headerLinks('submenu')}>
                <Link className={headerLinks('submenu-link')} to="#t">
                  Часто задаваемые вопросы
                </Link>
                {contacntHelperPhones &&
                  contacntHelperPhones.data &&
                  contacntHelperPhones.data.slice(0, 1).map((items, id) => {
                    return (
                      items &&
                      items.phone && (
                        <Link
                          key={id}
                          to={`tel:${items.phone.E164}`}
                          className={headerLinks('submenu-link')}>
                          {items.phone.international}
                        </Link>
                      )
                    );
                  })}
              </div>
            </div>
          </div>
          {isLogin !== null && isLogin ? (
            <div className={headerLinks('item')}>
              <Icon externalClassName={headerLinks('icon')} stroke iconName="profile" />
              кабинет
              <div className={headerLinks('submenu-wrapper')}>
                <div className={headerLinks('submenu')}>
                  <Link className={headerLinks('submenu-link')} to="/account">
                    Редактировать профиль
                  </Link>
                  {CHANGE_PASSWORD && (
                    <Link className={headerLinks('submenu-link')} to={CHANGE_PASSWORD}>
                      Изменение пароля
                    </Link>
                  )}
                  <Link className={headerLinks('submenu-link')} to="/account#messages">
                    Сообщения
                  </Link>
                  <p className={headerLinks('submenu-text')}>
                    Статус: {isPaswordVerified ? <>верифицирован</> : <>не верифицирован</>}
                  </p>
                  <Link className={headerLinks('submenu-link')} to="/account#proof-of-identity">
                    Подтвердить личность
                  </Link>
                  <p className={headerLinks('submenu-link')} onClick={() => handlerLogout()}>
                    Выход
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <Link
              onClick={(event) => redirectToLogin(event)}
              className={headerLinks('item')}
              to="/login">
              <Icon externalClassName={headerLinks('icon')} stroke iconName="profile" />
              войти
            </Link>
          )}
        </>
      ) : (
        <Icon
          externalClassName={headerLinks('icon')}
          iconName="burger"
          handleClick={() => dispatch(toggleMenuModal())}
        />
      )}
    </div>
  );
};

export default HeaderLinks;
